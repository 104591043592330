<template>
  <div id="result-scores-info">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <template v-if="loading">
            <loading>{{ resultProgress }}</loading>
          </template>
          <template v-else>
            <template v-if="result_path">
              <iframe :src="result_path" />
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import gql from 'graphql-tag'
import { fetchAppUrl } from '@/assets/js/app_info.js'

export default {
  name: 'StudentsPdf',
  data() {
    return {
      pageTitle: 'Students In School - PDF',
      schoolId: null,
      loading: false,
      result_path: null,
      schoolClass: {},
      terms: null,
      termTitle: null,
      termId: null,
      schoolClassId: null,
      path: null,
      asyncJob: null,
      skipQuery: true,
      resultProgress: 'Fetching PDF...',
    }
  },
  apollo: {
    asyncJob: {
      query: gql`
        query asyncJobQuery($id: Int!) {
          asyncJob(id: $id) {
            jobId
            status
            progressCount
            result
          }
        }
      `,
      variables() {
        return { id: parseInt(this.async_job_id) }
      },
      skip() {
        return this.skipQuery
      },
      pollInterval: 500,
    },
  },
  watch: {
    asyncJob(data) {
      if (data.status === 'Done') {
        this.loading = false
        this.skipQuery = true
        this.result_path = data.result
      } else {
        this.loading = true
        this.resultProgress = data.status
      }
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)

    this.$store.commit('setSubMenus', [
      {
        name: 'Students',
        route: `/school/${this.schoolId}/students_in_school`,
      },
    ])

    fetchAppUrl().then((path) => {
      axios
        .get(`${path}/report`, {
          params: {
            school_id: this.schoolId,
            context: 'Students Pdf',
          },
        })
        .then((response) => {
          this.skipQuery = false
          this.async_job_id = response.data.async_job_id
        })
        .catch((error) => {
          // console.log(error.message);
        })
    })
  },
}
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100%;
}
</style>
